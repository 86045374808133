.writer_type{
    display: flex;
    height: 60px;
    justify-content: center;
}

@media (min-width: 820px) {
    .writer_type{
        justify-content: start;
    }   
}