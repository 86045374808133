.landing-page-head-container_img {
    width: 100%;
    height: auto;
    object-fit: cover;
    justify-self: end
}

.whatsapp-image_container {
    width: 70px;
    height: 70px;
    position: fixed;
    bottom: 6vh;
    right: 7vh;
}